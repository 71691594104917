<template>
  <b-pagination
    id="pagination"
    v-model="pagination.currentPage"
    :total-rows="pagination.totalLines"
    :per-page="pagination.defaultSize"
    first-number
    last-number
    prev-class="prev-item"
    next-class="next-item"
    class="d-flex justify-content-center flex-wrap mt-1 mb-0"
    hide-goto-end-buttons
    hide-ellipsis
    @page-click="handlePage"
  >
    <template #prev-text>
      <feather-icon
        icon="ChevronLeftIcon"
        size="18"
      />
    </template>
    <template #next-text>
      <feather-icon
        icon="ChevronRightIcon"
        size="18"
      />
    </template>
  </b-pagination>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {

  components: {
    BPagination,
  },
  props: {
    pagination: {
      required: true,
      type: Object,
    },
  },

  methods: {
    handlePage(event, page) {
      this.$emit('pageClicked', page)
    },
  },
}
</script>

<style>
.page-link {
  color: #f19596 !important;
  margin-bottom: 1rem;
}

.page-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.page-link:hover {
  color: #f19596;
  border-color: #f19596;
}

.page-item.active .page-link {
  background-color: #f19596;
  border-color: #f19596;
  color: #fff !important;
}

.page-item.next-item .page-link:hover, .page-item.prev-item .page-link:hover {
  background-color: #f19596 !important;
  color: #fff !important;
}
</style>
